/********************************************************************
 * Do not update this file manually!
 * Use 'yarn sync-flags' inside 'editor-plugin-ai' folder to update it
 *********************************************************************/

export const EDITOR_AI_PLATFORM_FEATURE_FLAGS = {
	platform_editor_ai_translation_prompts: {
		type: 'boolean',
	},
	platform_editor_ai_translations_quick_insert: {
		type: 'boolean',
	},
	platform_editor_ai_interrogate_with_convo_ai: {
		type: 'boolean',
	},
	'platform.reformat-issue-with-template-in-jira_jc0pb': {
		type: 'boolean',
	},
	'platform.editor.ai-rovo-agents': {
		type: 'boolean',
	},
	platform_editor_ai_proactive_spelling_and_grammar: {
		type: 'boolean',
	},
	'rovo-chat-browse-agents': {
		type: 'boolean',
	},
	platform_editor_ai_suggested_response_fg: {
		type: 'boolean',
	},
	platform_editor_ai_enable_ai_action_items_block: {
		type: 'boolean',
	},
	platform_editor_ai_proactive_ai_md_plus: {
		type: 'boolean',
	},
	platform_editor_ai_proactive_sg_quality_filters: {
		type: 'boolean',
	},
	platform_editor_ai_enable_ai_summary_blocks: {
		type: 'boolean',
	},
	platform_editor_ai_assistance_service: {
		type: 'boolean',
	},
};
