import { useEffect, useState } from 'react';

import { type UseLocationHref } from './types';

const isLocationAvailable = (): boolean =>
	typeof globalThis !== 'undefined' && typeof globalThis.location !== 'undefined';

export const useLocationHref = (): UseLocationHref => {
	const [loc, setLoc] = useState<UseLocationHref>(
		isLocationAvailable() ? globalThis.location.href : undefined,
	);

	useEffect(() => {
		setLoc(globalThis.location.href);
	}, []);

	return loc;
};
