import FeatureGates from '@atlaskit/feature-gate-js-client';

import { preloadContent } from '@confluence/content-body/entry-points/preloadContent';
import { preloadSpace } from '@confluence/load-edit-page/entry-points/preloadSpace';
import { preloadInlineComments } from '@confluence/inline-comments-queries/entry-points/preloadInlineComments';
import { preloadScreen } from '@confluence/screen/entry-points/preloadScreen';
import {
	query,
	bestEffortTask,
	getPreloaderFnContext,
	prepareGuardExceptionTask,
} from '@confluence/query-preloader-tools';
import { PAGE_TREE_STATUSES } from '@confluence/page-tree/entry-points/pageTreeStatuses';
import {
	preloadPageTree,
	PAGE_TREE_QUERY_BEST_EFFORT_TASK_NAME,
} from '@confluence/page-tree/entry-points/preloadPageTree';
import { preloadContentPrerequisites } from '@confluence/content-prerequisites/entry-points/preloadContentPrerequisites';
import { preloadMacrosSSR } from '@confluence/fabric-extension-handlers/entry-points/preloadMacrosSSR';
import { preloadCustomHeaderAndFooter } from '@confluence/custom-header-footer/entry-points/preloadCustomHeaderAndFooter';
import type {
	SpaceOverviewQueryVariables as V,
	SpaceOverviewQueryType as Q,
} from '@confluence/space-overview-query';
import { SpaceOverviewQuery } from '@confluence/space-overview-query';
import { isCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import { getSSRFeatureFlag } from '@confluence/ssr-utilities';
import { CUSTOM_SITES_PAGE_TITLE_FF } from '@confluence/emoji-title/entry-points/constants';
import { preloadPageTitleContentProperties } from '@confluence/custom-sites-extensions/entry-points/preloadPageTitleContentProperties';

import { preloadGroupedButtons } from './preloadGroupedButtons';

type PreloadSpaceOverviewParameters = {
	spaceKey: string;
	includeAlias: boolean;
	homepageId: string | undefined;
};

export const preloadSpaceOverviewQuery = (spaceKey: string) => {
	return query<Q, V>({
		query: SpaceOverviewQuery,
		variables: {
			spaceKey,
			excludeOperations: Boolean(!isCompanyHubSpaceKey(spaceKey)),
		},
	});
};

const preloadSpaceOverviewQueries = async ({ homepageId = '', spaceKey }): Promise<any> => {
	const { userId, featureFlags, isLicensed } = await getPreloaderFnContext();

	const tasks: Promise<any>[] = [];

	tasks.push(
		prepareGuardExceptionTask('ContentAndMacros', () =>
			preloadContent({
				contentId: homepageId,
				spaceKey,
				isBlog: false,
				featureFlags,
			}).then(({ result, hasErrors }): Promise<any> | void => {
				const contentId = homepageId || result?.data?.content?.nodes[0]?.id;
				// in the event that there are errors (no id from result) and the homepageId is not valid (error when loading the space),
				// bypass preloadContentPrereqs since contentId is undefined
				if (!contentId) {
					return Promise.resolve();
				}
				if (hasErrors) {
					return preloadContentPrerequisites(
						contentId,
						spaceKey,
						Boolean(featureFlags['confluence.frontend.space.alias']),
					);
				} else if (process.env.REACT_SSR) {
					return preloadMacrosSSR({
						contentId,
						featureFlags,
						useMultipleMacrosQuery: false,
						contentNodes: result?.data?.content?.nodes,
					});
				}
			}),
		),
		preloadGroupedButtons({
			contentId: homepageId,
			spaceKey,
			userId,
			isLicensed,
		}),
		preloadInlineComments({
			pageId: homepageId,
			spaceKey,
		}),
	);

	const isCustomSitesPageTitleFFOn = featureFlags[CUSTOM_SITES_PAGE_TITLE_FF];
	if (isCustomSitesPageTitleFFOn) {
		tasks.push(preloadPageTitleContentProperties({ contentId: homepageId, spaceKey }));
	}
	if (process.env.REACT_SSR) {
		const shouldDisablePageTreeBestEffort = FeatureGates.checkGate(
			'confluence_remove_page_tree_best_effort',
		);
		tasks.push(
			bestEffortTask(
				PAGE_TREE_QUERY_BEST_EFFORT_TASK_NAME,
				() =>
					preloadPageTree({
						contentId: homepageId,
						spaceKey,
						statuses: PAGE_TREE_STATUSES,
						isSuperAdmin: false,
					}),
				shouldDisablePageTreeBestEffort,
			),
		);
	}
	return Promise.all(tasks);
};

export function preloadSpaceOverview({
	spaceKey,
	includeAlias,
	homepageId,
}: PreloadSpaceOverviewParameters): Promise<any> {
	const shouldPreloadUsingSpaceKey =
		getSSRFeatureFlag('confluence.ssr.remove.preload.space.overview.query') &&
		Boolean(process.env.REACT_SSR);

	const promises = [
		preloadScreen(spaceKey) as Promise<any>,
		preloadSpace(spaceKey, includeAlias) as Promise<any>,
		preloadCustomHeaderAndFooter(spaceKey),
	];

	if (homepageId) {
		// In some case we append homepageId as a query param. Then preload with it.
		// next/packages/space-overview/src/SpaceOverview.tsx still needs SpaceOverviewQuery but others are not depending on it
		promises.push(
			preloadSpaceOverviewQuery(spaceKey),
			preloadSpaceOverviewQueries({ homepageId, spaceKey }),
		);
	} else if (shouldPreloadUsingSpaceKey) {
		// Use new query to preload as space key then transform to the old queries.
		// next/packages/space-overview/src/SpaceOverview.tsx still needs SpaceOverviewQuery but others are not depending on it
		promises.push(preloadSpaceOverviewQuery(spaceKey), preloadSpaceOverviewQueries({ spaceKey }));
	} else {
		// If we have no information on home id and yet using old query, we need to fetch it first.
		promises.push(
			preloadSpaceOverviewQuery(spaceKey).then((res) => {
				// Because homepageId was provided by the function caller, it may or may
				// not be out of sync with backend:
				const actualHomepageId = res?.data?.space?.homepage?.id;
				if (actualHomepageId) {
					return preloadSpaceOverviewQueries({
						homepageId: actualHomepageId,
						spaceKey,
					});
				}
			}),
		);
	}

	return Promise.all(promises);
}
