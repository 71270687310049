import type { Diff, DiffContext } from './utils';

export type DiffPostProcesor = (context?: DiffContext) => (diffs: Diff[]) => Diff[];

/**
 * This post process attempts to join consecutive replacement only diffs which are seperated by whitespace. This way
 * they appear as a single suggestion replacement rather then seperate single ones.
 */
export const concatConsecutiveReplacements: DiffPostProcesor =
	(context?: DiffContext) =>
	(diffs: Diff[]): Diff[] => {
		const result: Diff[] = [];
		for (let i = 0; i < diffs.length; i++) {
			// This is a very specific use case, were consequetive replacements are seperated only by unchanged white space.
			// This essentially converts them to a single replacement group.
			if (
				diffs[i].type === -1 &&
				diffs?.[i + 1]?.type === 1 &&
				!diffs[i + 1].text.includes('\uFFF9') &&
				!diffs[i + 1].text.includes('\uFFFB')
			) {
				// look ahead for groups of white space follwed by replacements, we will want to join all of them together.
				const grouped: Diff[] = [];
				for (let j = i + 2; j < diffs.length; j++) {
					if (
						diffs?.[j]?.type === 0 &&
						diffs?.[j]?.text.trim().length === 0 &&
						diffs?.[j + 1]?.type === -1 &&
						diffs?.[j + 2]?.type === 1 &&
						!diffs[j + 1].text.includes('\uFFF9') &&
						!diffs[j + 1].text.includes('\uFFFB')
					) {
						grouped.push(diffs?.[j], diffs?.[j + 1], diffs?.[j + 2]);
						j += 3;
					} else {
						break;
					}
				}

				if (grouped.length) {
					const ot = grouped.reduce((acc, d) => acc + (d.type <= 0 ? d.text : ''), diffs[i].text);
					result.push({
						type: -1,
						text: ot,
						length: ot.length,
					});

					const nt = grouped.reduce(
						(acc, d) => acc + (d.type >= 0 ? d.text : ''),
						diffs[i + 1].text,
					);
					result.push({
						type: 1,
						text: nt,
						length: nt.length,
					});

					i += grouped.length + 1;
				} else {
					result.push(diffs[i]);
				}
			} else {
				result.push(diffs[i]);
			}
		}
		return result;
	};

export const ignoredTrailingFullStopsParentNodes = new Set<string>([
	'blockquote',
	'bulletList',
	'caption',
	'decisionItem',
	'decisionList',
	'heading',
	'listItem',
	'orderedList',
	// 'panel',
	'table',
	'tableRow',
	'tableCell',
	'tableHeader',
	'taskItem',
	'taskList',
]);

/**
 * This post process attempts to use the context of where diffs are located within a document and decided whether or not
 * they should show/hide a full stop suggestion
 */
export const ignoreTrailingFullStops: DiffPostProcesor =
	(context?: DiffContext) =>
	(diffs: Diff[]): Diff[] => {
		const result: Diff[] = diffs.concat();

		// use the original paragraph context to decide whether there should be a . at the end
		if (!!context && result.length > 1) {
			if (result[result.length - 2].type === -1 && result?.[result.length - 1]?.type === 1) {
				const removed = result[result.length - 2].text.trimEnd();
				const added = result[result.length - 1].text.trimEnd();

				const r1 = removed.charAt(removed.length - 1) === '.';
				const a1 = added.charAt(added.length - 1) === '.';
				const r2 = removed.substring(0, removed.length - 1);
				const a2 = added.substring(0, added.length - 1);

				if (removed !== added && (r1 || a1) && ((r1 && r2 === added) || (a1 && a2 === removed))) {
					const { from, doc } = context;
					const resolvedPos = doc.resolve(from);

					let found = ignoredTrailingFullStopsParentNodes.has(doc.nodeAt(from)?.type?.name ?? '');
					for (let d = resolvedPos.depth; d > 0 && !found; d--) {
						found = ignoredTrailingFullStopsParentNodes.has(resolvedPos.node(d).type.name);
					}

					if (found) {
						result.splice(-2, 2, {
							type: 0,
							text: diffs[diffs.length - 2].text,
							length: diffs[diffs.length - 2].length,
						});
					}
				}
			}
		}

		return result;
	};

export const ignoreTrailingSpaces: DiffPostProcesor =
	(context?: DiffContext) =>
	(diffs: Diff[]): Diff[] => {
		const result: Diff[] = diffs.concat();

		// use the original paragraph context to decide whether there should be a . at the end
		if (!!context && result.length > 1) {
			if (result[result.length - 2].type === -1 && result?.[result.length - 1]?.type === 1) {
				const removed = result[result.length - 2].text.trimEnd();
				const added = result[result.length - 1].text.trimEnd();

				if (removed === added) {
					result.splice(-2, 2, {
						type: 0,
						text: diffs[diffs.length - 2].text,
						length: diffs[diffs.length - 2].length,
					});
				}
			}
		}

		return result;
	};

/**
 * This collecitons contains a one-way mapping identifying replacement direction and targets which should be ignored from
 * the diff check. If you want a diff to be ignored 2-ways then it needs to be listed twice with the direction reversed.
 *
 * This list is case-insensitive.
 */
export const ignoredDirectReplacements = new Map<string, string>([
	['&', 'and'],
	['and', '&'],
]);

export const ignoreDirectReplacements: DiffPostProcesor =
	(context?: DiffContext) =>
	(diffs: Diff[]): Diff[] => {
		// If the replacements is change & -> and then we should ignore it
		const result: Diff[] = [];
		for (let i = 0; i < diffs.length; i++) {
			// We only care about replacement diffs
			if (diffs[i].type === -1 && diffs?.[i + 1]?.type === 1) {
				if (
					ignoredDirectReplacements.get(diffs[i].text.trim().toLowerCase()) ===
					diffs[i + 1].text.trim().toLowerCase()
				) {
					result.push({
						type: 0,
						text: diffs[i].text,
						length: diffs[i].length,
					});

					i += 1;
				} else {
					result.push(diffs[i]);
				}
			} else {
				result.push(diffs[i]);
			}
		}
		return result;
	};

/**
 * This will convert delete operations or blank replacements ops to a previous word replacement instead.
 */
export const convertJoinedDeletesIntoReplacement: DiffPostProcesor =
	(context?: DiffContext) =>
	(diffs: Diff[]): Diff[] => {
		const result: Diff[] = [];

		for (let i = 0; i < diffs.length; i++) {
			// This is a very specific use case, were consequetive replacements are seperated only by unchanged white space.
			// This essentially converts them to a single replacement group.
			if (
				diffs[i].type === 0 &&
				diffs?.[i + 1]?.type === -1 &&
				diffs?.[i + 2]?.type !== 1 &&
				diffs?.[i]?.text.trim().length !== 0
			) {
				// Removing to the end of a diff
				const rightIndex = [' ', '\uFFFB', '\uFFF9'].reduce((v, c) => {
					const x = diffs[i].text.lastIndexOf(c);
					return x !== -1 && x > v ? x : v;
				}, 0);

				const a = diffs[i].text.substring(0, rightIndex + 1);
				result.push({
					type: 0,
					text: a,
					length: a.length,
				});

				const b = diffs[i].text.substring(rightIndex + 1) + diffs[i + 1].text;
				result.push({
					type: -1,
					text: b,
					length: b.length,
				});

				const c = diffs[i].text.substring(rightIndex + 1);
				result.push({
					type: 1,
					text: c,
					length: c.length,
				});

				i += 1;
			} else {
				result.push(diffs[i]);
			}
		}

		return result;
	};
